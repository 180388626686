<template>
  <div class="mt-10">
    <div class="pb-4" v-if="showTitle || showTitle === undefined">
      {{ getBeautifyDate() }}
    </div>
    <div class="popUpWrapper">
      <div
        v-for="(court, index) in mergedCourts"
        :key="index"
        :class="[{ selected: court.id === resourceSelectedId }]"
        @click="
          resourceSelectedId != court.id
            ? (resourceSelectedId = court.id)
            : (resourceSelectedId = null),
            selectedCourt = court
        "
        class="text-left"
      >
        <div class="cursor-pointer">
          <div class="pb-2 noline w-full">
            <div>
              <SvgContainer name="arrowDownSmallIcon" />
              <ResourceLabel
                :sport="sport"
                :data="court"
                :showName="!groupResources"
                :isBooking="isBooking"
              />
            </div>
          </div>
        </div>
        <div class="dropdown">
          <div class="flex pb-3">
            <div
              v-for="(option, optionIndex) in court.courtPrices"
              :key="optionIndex"
              @click="selectDuration($event, option.duration)"
              :class="{
                        selected: option.duration === durationSelected && court.id === resourceOptionSelectedId,
                        active: option.duration === durationPreferred
                      }"
              class="optionButton md:p-4 sm:p-4 relative cursor-pointer"
            >
              <div>{{ option.amountStr }}</div>
              <div class="openingOpacityText text-sm">
                {{ option.duration + " " + $t("min") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        checkoutOnDurationSelected === false ||
        checkoutOnDurationSelected === undefined
      "
      class="search-btn flex justify-center items-center m-6 cursor-pointer"
      @click="checkout()"
    >
      {{ $t("text_continue") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRaw } from "vue";
import SvgContainer from "@/views/SvgContainer.vue";
import { beautifyDate } from "@/helpers/dateHelper";
import { customGlobalError } from "@/helpers/errorHelper";
import { Format } from "@/helpers/formatHelper";
import ResourceLabel from "@/components/booking/resourceLabel.vue";
import { MatchFilter } from "@/custom-models/matchFilter";
import { mergeResources, transformData } from "@/helpers/bookingHelper";
import { Court } from "@/custom-models/mergeResources"
import { PaymentTypeModeDTO } from "models";



export default defineComponent({
  name: "resourcePrice",
  props: [
    "resources",
    "date",
    "sport",
    "showTitle",
    "checkoutOnDurationSelected",
    "groupResources",
    "isBooking"
  ],
  emits: ["priceSelected"],
  components: {
    SvgContainer,
    ResourceLabel
  },
  data() {
    return {
      resourcePrices: [],
      durationSelected: null as number |null,
      durationPreferred: null as number |null,
      resourceSelectedId: 0 as number || null,
      resourceOptionSelectedId: 0 as number || null,
      mergedCourts: [] as Court[],
      selectedCourt: {} as Court,
      Format: Format
    };
  },
  mounted() {
    this.resourcePrices = this.resources;
    if (this.resourcePrices === undefined || this.resourcePrices.length == 0) {
      customGlobalError(this.$t("search_no_resources_found"));
      return;
    }
    try {
      //Format.isValidFilter(sport, option.attributes)" pasar esto en resources primero para eliminar los que llegan corruptos
      if(this.groupResources){
        this.mergedCourts = mergeResources(this.resources);
      }else{
        this.mergedCourts = transformData(this.resources);
      }
    } catch (error) {
      customGlobalError(this.$t("search_no_resources_found"));
      return;
    }
    var resourceSelected: any = this.mergedCourts[0];
    if (
      resourceSelected.courtPrices === null ||
      resourceSelected.courtPrices.length == 0
    ) {
      customGlobalError(this.$t("search_no_resources_found"));
      return;
    }
    const courtPrice = resourceSelected.courtPrices[0];
    this.selectedCourt = resourceSelected;
    this.resourceSelectedId = resourceSelected.id;
    const existsPrefered = resourceSelected.courtPrices.some((price: { duration: any; }) => price.duration === resourceSelected.preferredDuration);
    this.selectDuration(null, courtPrice.duration);
    if(existsPrefered){
      this.durationPreferred = resourceSelected.preferredDuration;
      this.selectDuration(null, this.durationSelected!);
    }
  },
  methods: {
    selectDuration(e: MouseEvent | null, duration: number): void {
      if(e){
        e.preventDefault();
        e.stopPropagation();
      }
      this.resourceOptionSelectedId = this.resourceSelectedId;
      this.durationSelected = duration;
      if (this.checkoutOnDurationSelected === true) {
        this.checkout();
      }
    },
    checkout() {
      const court = this.mergedCourts.find(
        (y: any) => y.id === this.resourceSelectedId
      );
      if (court === undefined) {
        customGlobalError(this.$t("search_no_resources_found"));
        return;
      }
      let price: any = {};
      for (var option of court.courtPrices) {
        if (option.duration === this.durationSelected) {
          price = option;
          break;
        }
      }
     
      //sobreescribimos price para quitar los atributos que no usamos y para que no de error el btoa con caracteres fuera del Latin1(ascii 0-255)
      price = {
        grossAmount: price.rawPrice,
        netAmount: price.netAmount,
        currencyCode: price.currencyCode,
        locale: price.locale,
        amountStr:price.amountStr
      };
      const allowedPaymentMethods = (court.courtPrices.find(courtPrices => courtPrices.duration === this.durationSelected)?.allowedPaymentMethods || []) as Array<PaymentTypeModeDTO>;

      const query: any = {
        resourceId: this.resourceSelectedId,
        duration: this.durationSelected,
        price: price,
        sport: this.sport,
        date: this.date,
        attributes: court.attributes,
        name: court.name,
      };
      this.$emit("priceSelected", query, toRaw(allowedPaymentMethods));// lo mando por separado ya que da problemas con los array proxy si estan dentro de query

    },
    getBeautifyDate() {
      return beautifyDate(this.date, true, false);
    },
    
  },
});
</script>

<style scoped>
.popUpWrapper {
  max-height: 330px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 12px;
}

.dropdown {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease 0.15s;
}

.selected .dropdown {
  gap: 5%;
  border-bottom: 1px solid var(--background-search);
  height: 110px;
  overflow: hidden;
  padding: 0.75rem !important;
  transition: 0.5s;
  opacity: 1;
}

.optionButton.selected {
  background: var(--color3) !important;
}

</style>