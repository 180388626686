<template>
  <div class="pb-8">
    <Header></Header>
    <div class="flex items-center">
      <div class="md:fixed top-21 left-[10%] z-[100] xxsm:ml-3 xxsm:mr-3">
        <HistoryBack />
      </div>
      <div
        class="custom-gradient text-center text-2xl not-italic font-semibold md:pb-2 md:mb-6 md:mt-16 md:flex-grow md:flex md:items-center md:justify-center sm:text-sm"
      >
        <span v-if="booking.type?.code == bookingType.MATCH">{{
          $t("match_details")
        }}</span>
        <span v-else>{{ $t("booking_details") }}</span>
      </div>
    </div>
    <div class="flex flex-wrap sm:block justify-around m-1">
      <BookingDetail
        v-if="booking.id !== undefined"
        :booking="booking"
        :showChat="user.id !== 0"
        :showResourceName="true"
        @bookingCancelled="handleBookingCancelled"
        :showAddCalendar="true"
        :key="refreshKey"
      />
      <div class="paymentBoxRight relative">
        <AddPlayers
          v-if="booking.id !== undefined"
          :bookingData="booking"
          :mode="mode"
          @setUpCompleted="openPaymentMethodHandler"
          :key="refreshKey"
        ></AddPlayers>
      </div>
    </div>
    <Transition>
      <PopUpLight
        v-if="openPaymentPopUp"
        :showOkButton="true"
        :showClose="false"
        @closed="closePaymentMethod()"
      >
        <div>
          <div>
            <h4 class="text-center">{{ alreadySigned }}</h4>
            <h5 class="mt-4 text-center">{{ payMsg }}</h5>
            <div class="flex mt-4 justify-around">
              <BorderButton @clicked="pay" :text="$t('pay')"></BorderButton>
              <BorderButton
                @clicked="notPay"
                :text="$t('continue_without_paying')"
              ></BorderButton>
            </div>
          </div>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight
        v-if="openSoccerPopUp"
        :showOkButton="true"
        :showClose="false"
        @closed="closeSoccerpopup()"
      >
        <div>
          <div>
            <p class="mt-14 text-center">{{ $t("download_app_for_match") }}</p>
          </div>
          <div class="flex">
            <div class="w-60 p-3 br8">
              <a
                :href="appLink.APPSTORE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Apple Store"
                  src="../assets/images/appIcons/app-store.svg"
                />
              </a>
            </div>
            <div class="w-60 p-3 br8">
              <a
                :href="appLink.GOOGLEPLAY"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Google Play"
                  src="../assets/images/appIcons/google-play.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BookingDetail from "../components/booking/bookingDetail.vue";
import Header from "../components/header/header.vue";
import AddPlayers from "@/components/booking/addPlayers.vue";
import { Booking } from "@/custom-models/booking";
import {
  BookingStatus,
  PlayersMode,
  Category,
  BookingType,
  Sport,
  appLink,
} from "@/enum/constants";
import { BookingApi } from "@/service/BookingApi";
import { customGlobalError } from "@/helpers/errorHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import PopUpLight from "@/components/popup/popupLight.vue";
import BorderButton from "@/components/buttons/borderButton.vue";
import store from "../store";
import { Format } from "@/helpers/formatHelper";
import LocalStorage from "@/helpers/localStorage";
import HistoryBack from "@/components/form/historyBack.vue";
import { encodeString } from "@/helpers/dataHelper";

export default defineComponent({
  name: "BookingJoinView",
  components: {
    Header: Header,
    BookingDetail,
    AddPlayers,
    PopUpLight,
    BorderButton,
    HistoryBack,
  },
  data() {
    return {
      booking: {} as Booking,
      mode: PlayersMode.JOIN,
      openPaymentPopUp: false,
      payMsg: "",
      alreadySigned: "",
      query: "",
      bookingId: "",
      enabled: true,
      user: { id: 0 },
      refreshKey: 0,
      bookingType: BookingType,
      openSoccerPopUp: false as boolean,
      appLink: appLink,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
    this.user = JSON.parse(user);
  },
  async mounted() {
    if (window.location.href.includes("join")) {
      document.body.style.overflowY = "scroll";
    }
    document.body.style.background = "none";

    this.bookingId = this.$route.params.id as string;
    if (Format.IsNull(this.bookingId)) {
      this.bookingId = this.$route.query.b as string;
      if (Format.IsNull(this.bookingId)) {
        customGlobalError(this.$t("wrong_url"), "/");
        return;
      }
    }
    const response = await BookingApi.findByHash1(this.bookingId.toString());
    if (response.data.sport && response.data.sport.code) {
      this.booking.sport = response.data.sport.code;
      if (this.booking.sport == Sport.SOCCER) {
        this.openSoccerPopUp = true;
        return;
      }
    }

    if (response.data.status != null) {
      if (response.data.status.code == BookingStatus.FINISHED) {
        this.$router.push("/booking-results/" + this.bookingId);
      }
    }
    if (response.data.status.code == BookingStatus.CANCELED) {
      this.mode = PlayersMode.CANCELED;
      this.enabled = false;
    }

    try {
      // parse server booking format to booking.ts format
      this.booking = BookingHelper.parse(response.data);
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
    }
  },
  methods: {
    async handleBookingCancelled() {
      //window.location.reload();
      const response = await BookingApi.findByHash1(this.bookingId.toString());
      if (response.data.status != null) {
        if (response.data.status.code == BookingStatus.FINISHED) {
          this.$router.push("/booking-results/" + this.bookingId);
        }
      }
      if (response.data.status.code == BookingStatus.CANCELED) {
        this.mode = PlayersMode.CANCELED;
        this.enabled = false;
      }
      this.booking = BookingHelper.parse(response.data);
      this.refreshKey++;
    },
    openPaymentMethodHandler(query: any) {
      if (query.type.code === Category.MATCH) {
        if (Format.IsNull(query.tenantId)) {
          return this.notPay();
        }
        this.query = query;
        return this.pay();
      }

      const allAreActive = query.players.every((y: any) => y.paid);
      if (allAreActive) {
        return this.notPay();
      }

      this.query = query;
      this.payMsg = this.$t("do_you_want_to_pay_question");
      this.alreadySigned = this.$t("already_signed");
      this.openPaymentPopUp = true;
    },
    closePaymentMethod() {
      this.openPaymentPopUp = false;
    },
    closeSoccerpopup() {
      this.openSoccerPopUp = false;
    },
    pay() {
      this.$router.push({
        path: "/checkout",
        query: { pl: encodeString(JSON.stringify(this.query)) },
      });
    },
    notPay() {
      LocalStorage.addWithTimout(this.booking.id as string, this.booking);
      return this.$router.push("/checkout-ok/" + this.booking.id);
    },
  },
});
</script>
<style scoped>
.paymentWrapper {
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 767px) {
  .paymentBoxRight {
    width: 700px;
    height: 754px;
    background: var(--background-color-box);
    /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
    border-radius: 20px;
  }
}
</style>