<template>
  <div
    class="WrapperError flex justify-center flex-col items-center sm:w-full sm:p-6"
  >
    <SvgContainer name="paymentErrorLock" />
    <p class="text-center text-3xl not-italic font-semibold mt-4 mb-4">
      {{ $t("oh_no") }}!
    </p>
    <p
      class="text-pink-400 text-center text-lg not-italic font-medium w-4/5 mb-12"
    >
      {{ errorMsg }}
    </p>
    <router-link v-if="$route.params.saleId !== 'undefined'" to="#" @click="retry()">
      <div
        class="buttomError cursor-pointer walletButton flex justify-center items-center"
      >
        {{ $t("retry") }}
      </div>
    </router-link>

    <router-link to="/">
      <div
        class="buttomError cursor-pointer walletButton flex justify-center items-center"
      >
        {{ $t("back_to_home") }}
      </div>
    </router-link>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SvgContainer from "@/views/SvgContainer.vue";
import { SaleApi } from "@/service/SaleApi";
import { decodeString } from "@/helpers/dataHelper";

export default defineComponent({
  name: "payError",
  components: {
    SvgContainer,
  },
  data() {
    return {
      errorMsg: null as string | null
    };
  },
  mounted() {
    this.errorMsg = decodeString(this.$route.params.errorMsg as string);
  },
  methods: {
    async retry() {
      const response: any = await SaleApi.retrySale(
        this.$route.params.saleId.toString()
      );
      const newSaleId = response.data;
      return this.$router.push({
        name: "checkoutPaymentView",
        params: {
          saleId: newSaleId.id,
          cancellationLimitDate: btoa(this.$route.params.cancellationLimitDate as string),
        },
      });
    },
  },
});
</script>
<style scoped>
.WrapperError {
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.buttomError {
  width: 327px;
  padding: 14px 0;
  gap: 10px;
  margin: 8px 0;
}
</style>